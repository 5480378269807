import * as cheerio from "cheerio";

import ArrowRight from "@/public/icons/ArrowRight.svg";
import ArrowUp from "@/public/icons/arrow-up.svg";
import { Button } from "antd";
import CardBlog from "@/components/blog/CardBlog";
import LayoutBlog from "@/layouts/blog";
import Link from "next/link";
import blogServices from "@/services/blog";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import { decode } from "html-entities";
import { useEffect, useState } from "react";
import { convertPost } from "@/utils/common";

export async function getServerSideProps({ locale }) {
  try {
    const responseTag = await blogServices.fetchBlogTags({ slug: locale });
    const currentTag = responseTag[0].id;
    const paramsRecentPost = {
      per_page: 4,
      page: 1,
      _embed: 1,
      tags: currentTag,
    };
    const [responseRecentPost, responseCategories, responseUsers] =
      await Promise.all([
        blogServices.fetchPosts(paramsRecentPost),
        blogServices.fetchCategory(),
        blogServices.fetchBlogUser({ page: 1, per_page: 30 }),
      ]);

    const categories = responseCategories
      .filter((c) => c.count > 0)
      .map((item) => ({
        ...item,
        label: decode(item.name),
        value: item.id,
      }));
    categories.unshift({
      value: "all",
      label: "Home",
    });

    const recentPost = convertPost(
      responseRecentPost.data,
      responseCategories,
      responseUsers
    );

    const newCategories = await Promise.all(
      categories.map(async (category) => {
        const params = {
          _embed: 1,
          per_page: 6,
          categories: category.id,
          tags: currentTag,
        };
        const response = await blogServices.fetchPosts(params);
        const posts = convertPost(response.data, categories, responseUsers);
        return {
          ...category,
          posts,
        };
      })
    );
    return {
      props: {
        recentPost: recentPost,
        categories: newCategories,
        tags: currentTag,
        ...(await serverSideTranslations(locale)),
      },
    };
  } catch (error) {
    // Sentry.captureMessage(error)
    return {
      props: {
        recentPost: [],
        categories: [],
        tags: 1,
        ...(await serverSideTranslations(locale)),
      },
    };
  }
}

export default function Blog({ categories, recentPost, tags }) {
  const { t } = useTranslation("blog");
  const [validCategories, setValidCategories] = useState(() => {
    return categories.filter((item) => item.posts && item.value !== "all");
  });

  useEffect(() => {
    setRecentPosts(recentPost);
    setValidCategories(
      categories.filter((item) => item.posts && item.value !== "all")
    );
  }, [recentPost, categories]);

  const [recentPosts, setRecentPosts] = useState(recentPost);

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSearch = async (keySearch) => {
    const paramsRecentPost = {
      per_page: 4,
      page: 1,
      _embed: 1,
      tags: tags,
      search: keySearch,
    };
    const responseRecentPost = await blogServices.fetchPosts(paramsRecentPost);
    const newRecentPost = convertPost(responseRecentPost.data, categories);
    setRecentPosts(newRecentPost);

    const newCategories = await Promise.all(
      validCategories.map(async (category) => {
        const params = {
          _embed: 1,
          per_page: 6,
          categories: category.id,
          tags: tags,
          search: keySearch,
        };
        const response = await blogServices.fetchPosts(params);
        const posts = convertPost(response.data, categories);
        return {
          ...category,
          posts,
        };
      })
    );
    setValidCategories(newCategories);
  };
  return (
    <LayoutBlog
      seoTitle={t("seo.title")}
      seoDescription={t("seo.desc")}
      categories={categories}
      onSearch={handleSearch}
    >
      <section>
        <div className="container-fuild">
          {recentPosts.length ? (
            <div className="recent-post pb-24 border border-t-0 border-l-0 border-r-0 border-solid border-[#E4E7EC]">
              <div className="grid grid-cols-3 gap-12 lg:grid-cols-2 md:grid-cols-1 xl:gap-6">
                {recentPosts.map((postItem, index) => (
                  <div
                    key={postItem.id}
                    className={`${
                      index === 0 ? "col-span-full" : "col-span-1"
                    }`}
                  >
                    <CardBlog
                      blog={postItem}
                      category={postItem.category}
                      isHighlight={index === 0}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          {validCategories.length &&
            validCategories.map((item) => (
              <div key={item.id}>
                {item.posts.length ? (
                  <div className="py-24 last:pb-16 last:border-none border border-t-0 border-l-0 border-r-0 border-solid border-[#E4E7EC] post-category">
                    <div className="flex items-center justify-between mb-16">
                      <h2
                        className="text-display-lg lg:text-display-md text-new-primary-900"
                        dangerouslySetInnerHTML={{ __html: item.name || "" }}
                      ></h2>
                      <Link href={`/blog/category/${item.slug}?id=${item.id}`}>
                        <Button className="flex items-center gap-1">
                          <span>{t("button.view_all", { ns: "common" })}</span>
                          <ArrowRight className="object-contain w-6 h-6" />
                        </Button>
                      </Link>
                    </div>
                    <div className="grid grid-cols-3 gap-12 lg:grid-cols-2 md:grid-cols-1 xl:gap-6">
                      {item.posts.map((postItem) => (
                        <div key={postItem.id} className={"col-span-1"}>
                          <CardBlog blog={postItem} category={item} />
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
        </div>
        <div className="flex items-center justify-center mb-24">
          <Button
            type="link"
            onClick={handleScrollTop}
            className="flex items-center gap-1 text-[#475467] text-[18px] leading-7 font-semibold ms:text-[16px] md:leading-6"
          >
            <ArrowUp />
            <span>{t("back_to_top", { ns: "common" })}</span>
          </Button>
        </div>
      </section>
    </LayoutBlog>
  );
}
